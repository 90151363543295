import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/mapa-stranok/" className="sel">
              {" "}
              &gt; Mapa stránok
            </a>
          </div>
          <div id="page_content">
            <h1>Mapa stránok</h1>
            <ul id="sitemap">
              <li>
                <a className="big" href="/">
                  Hlavná stránka
                </a>
                <a className="small" href="/zasady_ochrany_osobnych_udajov/">
                  Zásady ochrany osobných údajov
                </a>
                <a className="small" href="/zasady_pouzivania_suborov_cookies/">
                  Zásady používania súborov cookies
                </a>
                <a className="small" href="/pravne_oznamenie/">
                  Právne oznámenie
                </a>
                <a className="small" href="/mapa-stranok/">
                  Mapa stránok
                </a>
                <a className="small" href="/slovnik_pojmov/">
                  Slovník pojmov
                </a>
                <a className="small" href="/priloha_A/">
                  Príloha A
                </a>
                <a className="small" href="/televizna_reklama/">
                  Televízna reklama
                </a>
                <a className="big" href="/nadcha/">
                  NÁDCHA
                </a>
                <a className="big" href="/nadcha_u_deti/">
                  NÁDCHA U DETÍ
                </a>
              </li>
              <li>
                <a className="big" href="/lieky/">
                  Lieky
                </a>
                <a className="small" href="/lieky/olynth_05/">
                  OLYNTH® 0,05%
                </a>
                <a className="small" href="/lieky/olynth_ha_05/">
                  OLYNTH® HA 0,05%{" "}
                </a>
                <a className="small" href="/lieky/olynth_01/">
                  OLYNTH® 0,1%
                </a>
                <a className="small" href="/lieky/olynth_ha_01/">
                  OLYNTH® HA 0,1%
                </a>
              </li>
              <li>
                <a className="big" href="/radca/">
                  Radca
                </a>
                <a href="/radca/" className="meddium">
                  NÁDCHA
                </a>
                <a
                  href="/radca/nadcha/ako_si_spravne_cistit_nos/"
                  className="tab"
                >
                  Ako si správne čistiť nos pri nádche?
                </a>
                <a href="/radca/nadcha/spoznajte_typy_nadchy/" className="tab">
                  Spoznajte typy nádchy
                </a>
                <a
                  href="/radca/nadcha/zabojujte_s_nadchou_rychlo/"
                  className="tab"
                >
                  Zabojujte s nádchou rýchlo
                </a>
                <a
                  href="/radca/nadcha/alergicka_nadcha_spoznajte_ju_a_predchadzajte_jej/"
                  className="tab"
                >
                  Alergická nádcha – spoznajte ju a predchádzajte jej
                </a>
                <a href="/radca/nadcha/co_je_nadcha/" className="tab">
                  Čo je nádcha?
                </a>
                <a
                  href="/radca/nadcha/co_je_zapal_prinosovych_dutin/"
                  className="tab"
                >
                  Čo je zápal prinosových dutín?
                </a>
                <a
                  href="/radca/nadcha/nasledky_neliecenej_nadchy/"
                  className="tab"
                >
                  Následky neliečenej nádchy
                </a>
                <a
                  href="/radca/nadcha/zvladnite_zapal_prinosovych_dutin/"
                  className="tab"
                >
                  Zvládnite zápal prinosových dutín
                </a>
                <a href="/radca/nadcha/myty_o_nadche/" className="tab">
                  Mýty o nádche
                </a>
                <a href="/radca/" className="meddium">
                  PRECHLADNUTIE
                </a>
                <a
                  href="/radca/prechladnutie/domace_sposoby_boja_s_prechladnutim/"
                  className="tab"
                >
                  Domáce spôsoby boja s prechladnutím
                </a>
                <a
                  href="/radca/prechladnutie/styri_fazy_vzniku_prechladnutia/"
                  className="tab"
                >
                  Štyri fázy vzniku prechladnutia
                </a>
                <a
                  href="/radca/prechladnutie/ako_sa_ochranit_pred_prechladnutim/"
                  className="tab"
                >
                  Ako sa ochrániť pred prechladnutím?
                </a>
                <a href="/radca/" className="meddium">
                  DETI
                </a>
                <a href="/radca/deti/upchaty_nos_u_detC3AD/" className="tab">
                  Upchatý nos u detí
                </a>
                <a
                  href="/radca/deti/imunita_dietata_dbajte_a_posilnujte/"
                  className="tab"
                >
                  Imunita dieťaťa – dbajte a posilňujte
                </a>
                <a
                  href="/radca/deti/Boj_s_infekciami_nadcha_a_kasel_u_dietata/"
                  className="tab"
                >
                  Boj s infekciami – nádcha a kašeľ u dieťaťa
                </a>
                <a href="/radca/deti/ako_naucit_dieta_siakat/" className="tab">
                  Ako naučiť dieťa siakať?
                </a>
              </li>
              <li>
                <a className="big" href="/upravte_liek/">
                  Test
                </a>
                <a className="big" href="/kontakt/">
                  Kontakt
                </a>
                <a className="big" href="/otazky_a_odpovede/">
                  Otázky a odpovede
                </a>
                <a className="big" href="/kde_kupit/">
                  KDE KÚPIŤ{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
